﻿@font-face {
    font-family: 'TradeGothic LT';
    src: url('../fonts/TradeGothicLT/TradeGothicLT.woff2') format('woff2'),
        url('../fonts/TradeGothicLT/TradeGothicLT.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TradeGothic LT';
    src: url('../fonts/TradeGothicLT/TradeGothicLT-Bold.woff2') format('woff2'),
        url('../fonts/TradeGothicLT/TradeGothicLT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
.tradegothic-lt {
    font-family: 'TradeGothic LT' !important;
}

@font-face {
    font-family: 'TradeGothic LT Std';
    src: url('../fonts/TradeGothicLTStd/TradeGothicLTStd-BdCn20.woff2') format('woff2'),
        url('../fonts/TradeGothicLTStd/TradeGothicLTStd-BdCn20.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
.tradegothic-lt-std {
    font-family: 'TradeGothic LT Std' !important;
}