﻿$colors: ();
$grays: ();

$gray-100: #FAFAFA;
$gray-300: #f2f2f2;
$gray-500: #7F7F7F;

// Palette globale
$primary: #C05C46;
$secondary: #F5F5F5;
$light: #FFFDFD;
$dark: #1A1D1A;
$danger: #FF7453;
$success: #8fae1b;

$enable-smooth-scroll: false;
$enable-rounded: true;
$rfs-rem-value: 10;
//$rfs-factor: 5;

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1680px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1200px,
  xxxl: 1620px
);

$cubic-bezier: cubic-bezier(.65,0,.35,1);

$cubic-bezier-inout-sine: cubic-bezier(0.45,0.05,0.55,0.95);

$cubic-bezier-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
$cubic-bezier-inout-expo: cubic-bezier(1,0,0,1);

$cubic-bezier-out-quint: cubic-bezier(0.23,1,0.32,1);
$cubic-bezier-inout-quint: cubic-bezier(.83, 0, .17, 1);

$grid-gutter-width: 3rem;


// Fonts & typography ************************************************************************
$font-family-sans-serif: 'TradeGothic LT', 'Arial', sans-serif;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: 1rem * 9;
$h2-font-size: 1rem * 6.6;
$h3-font-size: 1rem * 5.8;
$h4-font-size: 1rem * 5;
$h5-font-size: 1rem * 4;
$h6-font-size: 1rem * 3;

$headings-font-family:        'TradeGothic LT Std', 'Arial', sans-serif;
$headings-font-style:         null;
$headings-font-weight:        bold;
$headings-line-height:        1;
$headings-color:              null;

$mark-padding:                .1875em;
$mark-bg:                     $secondary;


// Buttons ***********************************************************************************
$btn-padding-y:               1.5rem;
$btn-padding-x:               4rem;
$btn-font-size:               1.5rem;
$btn-white-space:             null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            1rem;
$btn-padding-x-sm:            3rem;
$btn-font-size-sm:            1.3rem;

$btn-padding-y-lg:            2rem;
$btn-padding-x-lg:            4.75rem;
$btn-font-size-lg:            1.8rem;

$btn-font-weight:             700;

$btn-border-radius: 4rem;
$btn-border-radius-sm: 4rem;
$btn-border-radius-lg: 4rem;

$btn-hover-bg-shade-amount:       15%;
$btn-hover-bg-tint-amount:        15%;
$btn-hover-border-shade-amount:   20%;
$btn-hover-border-tint-amount:    10%;
$btn-active-bg-shade-amount:      20%;
$btn-active-bg-tint-amount:       20%;
$btn-active-border-shade-amount:  25%;
$btn-active-border-tint-amount:   10%;


// Forms *************************************************************************************
$input-padding-y: 1rem;
$input-padding-x: 1.5rem;
$input-color: $light;
$input-font-size: 1.8rem;
$input-border-color: $light;
$input-bg: transparent;
$input-border-width: 1px;
$input-border-radius: 0;
$input-placeholder-color: rgba($light, .5);
$input-box-shadow: 0 0 0 .35rem rgba($primary, .45) !important;
$input-focus-box-shadow: 0 0 0 .35rem rgba($primary, .45) !important;

$form-check-input-border: 1px solid $light;
$form-check-input-border-radius: 0;
$form-check-inline-margin-end: 3rem;
$form-check-input-checked-color:          $light;
$form-check-input-checked-bg-color:       $light;

$form-select-focus-box-shadow: 0 0 0 .35rem rgba($primary, .45) !important;
$form-select-feedback-icon-position: right calc(0.375em + 1.5rem) center;
$form-select-indicator-color:       $light;


// Breadcrumb *************************************************************************************
$breadcrumb-font-size:              1.5rem;
$breadcrumb-item-padding-x:         1rem;
$breadcrumb-margin-bottom:          0;


// Collapse ***************************************************************************************
$transition-collapse:         height .40s $cubic-bezier;
$transition-collapse-width:   width .40s $cubic-bezier;


// Border radius ***************************************************************************************
$border-radius:               .5rem;
$border-radius-sm:            1rem;
$border-radius-lg:            2.5rem;
$border-radius-xl:            5rem;
$border-radius-2xl:           10rem;
$border-radius-pill:          50rem;


// Accordion ******************************************************************************************
$accordion-bg:                            $light;
$accordion-padding-y:                     2rem;
$accordion-padding-x:                     0;
$accordion-border-color:                  rgba($dark, .7);
$accordion-button-padding-y:              calc($accordion-padding-y / 2);
$accordion-button-active-bg:              $light;
$accordion-button-active-color:           $dark;
$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path d='M4.251 181.1C7.392 177.7 11.69 175.1 16 175.1c3.891 0 7.781 1.406 10.86 4.25l197.1 181.1l197.1-181.1c6.5-6 16.64-5.625 22.61 .9062c6 6.5 5.594 16.59-.8906 22.59l-208 192c-6.156 5.688-15.56 5.688-21.72 0l-208-192C-1.343 197.7-1.749 187.6 4.251 181.1z'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path d='M4.251 181.1C7.392 177.7 11.69 175.1 16 175.1c3.891 0 7.781 1.406 10.86 4.25l197.1 181.1l197.1-181.1c6.5-6 16.64-5.625 22.61 .9062c6 6.5 5.594 16.59-.8906 22.59l-208 192c-6.156 5.688-15.56 5.688-21.72 0l-208-192C-1.343 197.7-1.749 187.6 4.251 181.1z'/></svg>");


// Alert ******************************************************************************************
$alert-padding-y:               2rem;
$alert-padding-x:               2rem;


// Tables ******************************************************************************************
$table-border-color:            $dark;
$table-cell-padding-y:        .5rem;
$table-cell-padding-x:        .25rem;
$table-cell-padding-y-sm:     .25rem;
$table-cell-padding-x-sm:     .25rem;


// Tooltip ****************************************************************************************
$tooltip-font-size:                 1.3rem;
$tooltip-padding-y:                 .5rem;
$tooltip-padding-x:                 1rem;
$tooltip-arrow-width:               1.2rem;
$tooltip-arrow-height:              .6rem;


// Toast ****************************************************************************************
$toast-max-width:                   480px;
$toast-background-color:            $light;