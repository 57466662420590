/* 1. Include configuration files (functions, variables, mixins, etc...) */
@import "../config";
@import "../variables";

/* 2. Set Variables */
$themeColor: $dark;

/* 3. Import core */
@import "../../../node_modules/swiper/swiper";

/* 4. Include modules */
// @import "../../../node_modules/swiper/modules/navigation/navigation";
@import "../../../node_modules/swiper/modules/pagination/pagination";
// @import "../../../node_modules/swiper/modules/scrollbar/scrollbar";
// @import "../../../node_modules/swiper/modules/autoplay/autoplay";
// @import "../../../node_modules/swiper/modules/free-mode/free-mode";
// @import "../../../node_modules/swiper/modules/grid/grid";
// @import "../../../node_modules/swiper/modules/manipulation/manipulation";
// @import "../../../node_modules/swiper/modules/parallax/parallax";
// @import "../../../node_modules/swiper/modules/lazy/lazy";
@import "../../../node_modules/swiper/modules/effect-fade/effect-fade";
// @import "../../../node_modules/swiper/modules/effect-coverflow/effect-coverflow";
// @import "../../../node_modules/swiper/modules/effect-flip/effect-flip";
// @import "../../../node_modules/swiper/modules/effect-cube/effect-cube";
// @import "../../../node_modules/swiper/modules/effect-cards/effect-cards";
// @import "../../../node_modules/swiper/modules/effect-creative/effect-creative";
// @import "../../../node_modules/swiper/modules/thumbs/thumbs";
// @import "../../../node_modules/swiper/modules/zoom/zoom";
// @import "../../../node_modules/swiper/modules/keyboard/keyboard";
@import "../../../node_modules/swiper/modules/mousewheel/mousewheel";
// @import "../../../node_modules/swiper/modules/virtual/virtual";
// @import "../../../node_modules/swiper/modules/hash-navigation/hash-navigation";
// @import "../../../node_modules/swiper/modules/history/history";
// @import "../../../node_modules/swiper/modules/controller/controller";
@import "../../../node_modules/swiper/modules/a11y/a11y";

/* 5. Add additional custom code here */
:root {
    --swiper-pagination-color: #{$dark};
    --swiper-pagination-bullet-size: 1.3rem;
    --swiper-pagination-bullet-width: 1.3rem;
    --swiper-pagination-bullet-height: 1.3rem;
    --swiper-pagination-bullet-inactive-color: transparent;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: .7rem;
    --swiper-pagination-progressbar-size: 3px;

    @include media-breakpoint-up(lg){
        --swiper-pagination-bullet-size: 2rem;
        --swiper-pagination-bullet-width: 2rem;
        --swiper-pagination-bullet-height: 2rem;
        --swiper-pagination-bullet-horizontal-gap: 1rem;
    }
}

.swiper-pagination {
    @include padding(3rem);
    font-size: 0;
    bottom: var(--swiper-pagination-bottom, 0) !important;
    text-align: start;
    z-index: 1;

    @include media-breakpoint-up(lg){
        padding: 4.7rem 3rem;
    }

    &.background--dark {
        .swiper-pagination-bullet {
            border-color: $light;
        }
        .swiper-pagination-bullet-active {
            background-color: $light;
        }
    }
}

.swiper-pagination-progressbar {
    bottom: 0;
    top: auto;
    position: static;
    padding: 0;
    border-radius: 1rem;
    overflow: hidden;
    max-width: 12.75rem;
    margin-top: 2.5rem;
    background-color: rgba($dark, .05);

    @include media-breakpoint-down(md){
        max-width: 10.3rem;
        margin-top: 1.5rem;
    }

    .swiper-pagination-progressbar-fill {
        border-radius: 1rem;
    }
}

.swiper-pagination-bullet {
    border: .2rem solid $dark;
    transition: border-color 600ms ease-out;

    &:first-child {
        margin-left: 0 !important;
    }
    &:last-child {
        margin-right: 0 !important;
    }
    
    &:focus,
    &:focus-visible {
        outline-offset: .5rem;
    }
}

.swiper-pagination-bullet-active {
    transition: background-color 600ms ease-out;
}

.swiper-navigation-btns {
    font-size: 0;
    @include padding(3rem);
    z-index: 1;

    .btn-prev {
        @include margin-right(1rem);
    }
    .btn-next {
        @include margin-left(1rem);
    }
}