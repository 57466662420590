/* 1. Include configuration files (functions, variables, mixins, etc...) */
@import "../config";

.prod-landing {
    background-color: rgba(var(--bs-light-rgb), 1);

    @include media-breakpoint-down(md){
        h1, .h1 {
            @include font-size(1.4rem * 10);
        }
        h2, .h2 {
            @include font-size(1.4rem * 8);
        }
        h3, .h3 {
            @include font-size(1.4rem * 7);
        }
        h4, .h4 {
            @include font-size(1.35rem * 6);
        }
        h5, .h5 {
            @include font-size(1.35rem * 5);
        }
        h6, .h6 {
            @include font-size(1.35rem * 4);
        }
    }

    section {
        position: relative;

        .inner {
            @include padding-top(12rem);
            @include padding-bottom(12rem);

            @include media-breakpoint-up(md){
                padding-left: calc($grid-gutter-width / 2);
                padding-right: calc($grid-gutter-width / 2);
            }

            @include media-breakpoint-up(xl){
                display: flex;
                align-items: center;
                min-height: 100vh;
            }
        }

        // &.download,
        // &.other-products {
        //     .inner {
        //         @include media-breakpoint-up(xl){
        //             min-height: 75vh;
        //         }
        //     }
        // }

        .pages-vert-side-img {
            width: 100%;
            height: 75vh;
            position: relative !important;

            @include media-breakpoint-up(md){
                width: 33.3%;
                height: 100%;
                position: absolute !important;
                top: 0 !important;
                bottom: auto !important;
            }
        }
    }

    // HERO *******************************************************************************************
    section.hero {
        overflow: hidden;

        .loading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include media-breakpoint-down(md){
                top: 40%;
            }
        }

        .inner {
            padding-top: 0;
            padding-bottom: 0;
            min-height: 100vh;

            @include media-breakpoint-down(xl){
                display: flex;
                align-items: center;
            }

            @include media-breakpoint-down(lg){
                height: calc(100vh - 6.2rem);
                min-height: 60rem;
            }

            @include media-breakpoint-down(md){
                height: auto;
                min-height: auto;
            }
        }

        .hero--wrap-prod {
            width: 100%;

            @include media-breakpoint-up(xl){
                width: calc(66.6% - $grid-gutter-width);
            }

            @include media-breakpoint-down(md){
                padding: 6rem 0;
            }

            .hero--anim-container {
                overflow: hidden;

                @include media-breakpoint-up(md){
                    margin-top: -10vh;
                }

                @include media-breakpoint-up(lg){
                    margin-top: -5vh;
                }

                .hero--img {
                    width: 100%;
                    height: auto;
        
                    @include media-breakpoint-up(md){
                        width: 75%;
                    }
        
                    @include media-breakpoint-up(lg){
                        width: auto;
                        height: 50vh;
                        max-height: 54rem;
                    }
                }
            }
        }
    }

    // INTRO *******************************************************************************************
    section.intro {
        background-color: $primary;

        .inner {
            min-height: auto;

            .text-wrapper {
                margin-top: 2rem;

                @include media-breakpoint-up(lg){
                    margin-top: 0;
                }

                &.text-columns {
                    @include media-breakpoint-up(md){
                        column-count: 2;
                        column-gap: 4rem;
                    }

                    p:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    // HIGHLIGHTS *******************************************************************************************
    section.highlights {
        .highlights--wrap-text {
            width: 100%;

            @include media-breakpoint-up(md){
                width: calc(60% - $grid-gutter-width);
                margin-left: 40%;
            }

            .highlights--item {
                &:not(:last-child){
                    @include margin-bottom(6rem);
                }
                
                @include media-breakpoint-up(xl){
                    padding-right: 17%;
                }
            }
        }
    }

    // NEWS / PRODUCT *******************************************************************************************
    section.news-product {
        .inner {
            
            .swiper {
                padding: 0 $grid-gutter-width;
                transition: opacity 1200ms $cubic-bezier-out-expo;
                transition-delay: 1s;

                @include media-breakpoint-up(md){
                    padding: 0 0 1.5rem;
                }

                @include media-breakpoint-up(xl){
                    padding: 3rem 7.5% 1.5rem 0;
                    
                    &.many-photos {
                        padding: 3rem 27.5% 1.5rem 0;
                        transform: translateX(-12.5%);
                    }    
                }

                .swiper-slide {
                    height: auto;

                    .news-thumb {
                        max-width: none;

                        .news-thumb--inner {
                            flex: auto;
                            height: auto;
                        }
                    }
                }
            }

            .swiper-navigation-btns {
                margin-top: -2rem;
                padding: 0;
                position: relative;
            }
        }
    }

    // GALLERY *******************************************************************************************
    section.gallery {
        .inner {
            padding-top: 6rem;
            padding-bottom: 0;

            @include media-breakpoint-up(md){
                padding-bottom: 3rem;
            }

            @include media-breakpoint-up(xl){
                padding-top: 0;
                padding-bottom: 0;
            }
            
            .swiper {
                padding: 0 $grid-gutter-width 5.5rem;
                transition: opacity 1200ms $cubic-bezier-out-expo;
                transition-delay: 1s;

                @include media-breakpoint-up(md){
                    padding: 0 0 1.5rem;
                }

                @include media-breakpoint-up(xl){
                    padding: 3rem 7.5% 1.5rem 0;
                    
                    &.many-photos {
                        padding: 3rem 27.5% 1.5rem 0;
                        transform: translateX(-12.5%);
                    }
                }

                .ratio {
                    background-color: $dark;

                    // @include media-breakpoint-down(lg){
                    //     --bs-aspect-ratio: 125%;
                    // }
    
                    .gallery--thumb {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        left: 0;
                        width: 100%;
                        height: auto;
                        margin: 0 auto;
                        transform: translateY(-50%);
                        transition: width 600ms $cubic-bezier;
                    }

                    @include media-breakpoint-up(xl){
                        &:hover {
                            .gallery--thumb {
                                width: 90%;
                            }
                        }
                    }
                }

                .btn-sm {
                    padding: .5rem 1.5rem;       
                }
            }

            .swiper-navigation-btns {
                margin-top: -2rem;
                padding: 0;
                position: relative;

                @include media-breakpoint-up(md){
                    margin-top: -2.75rem;
                }

                @include media-breakpoint-up(xl){
                    transform: translateX(25.5%);
                }
            }
        }
    }

    // VIDEO GALLERY *******************************************************************************************
    section.video-gallery {
        background-color: #000;

        .swiper {
            padding: 0 $grid-gutter-width;
            transition: opacity 1200ms $cubic-bezier-out-expo;
            transition-delay: 1s;

            @include media-breakpoint-up(md){
                padding: 0;
            }

            @include media-breakpoint-up(xl){
                width: 87.5%;
                margin: 0;

                &.many-videos {
                    padding: 0 27.5% 0 0;
                }
            }

            @include media-breakpoint-up(xxxl){
                width: 87%;

                &.many-videos {
                    padding: 0 27.5% 0 0;
                }
            }

            .swiper-slide {
                transition: opacity 600ms $cubic-bezier;
                opacity: .2;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }

                &.swiper-slide-active {
                    opacity: 1;

                    &::after {
                        display: none;
                    }
                }
            }

            .ratio {
                @include media-breakpoint-down(md){
                    --bs-aspect-ratio: 150%;
                }

                .video-gallery--thumb {
                    position: relative;
                    
                    @include media-breakpoint-down(md){
                        height: 100%;
                    }

                    img {
                        display: block;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }

                    .video-gallery--title {
                        position: absolute;
                        bottom: 6rem;
                        left: 0;
                        width: 100%;
                        color: $light;
                        text-align: center;

                        @include media-breakpoint-up(xl){
                            bottom: 0;
                            text-align: left;
                            @include padding(2rem 3.5rem);
                        }

                        @include media-breakpoint-up(xxxl){
                            @include padding(3rem 5rem);
                        }
                    }

                    .btn {
                        position: absolute;
                        right: 50%;
                        bottom: 50%;
                        transform: translate(50%, 50%) scale(1.5);
                        
                        @include media-breakpoint-up(xl){
                            @include rfs(2rem, bottom);
                            @include rfs(3.5rem, right);
                            transform: none;
                        }
                    }

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 50%;
                        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%);
                    }
                }
            }

            .swiper-navigation-btns {
                padding: 0;
                position: relative;
                margin-top: -2rem;

                @include media-breakpoint-up(md){
                    margin-top: -2.75rem;
                }
            }
        }
    }

    // FEATURES ******************************************************************************************
    section.features {
        background-color: #EFCB92;

        .title {
            color: darken($primary, 15%);
            margin-bottom: 4rem;
        }

        .row {
            --bs-gutter-x: 6rem;
            transition: opacity 1200ms $cubic-bezier-out-expo;
            transition-delay: 1s;
        }
    }

    // COMMON QUESTIONS ******************************************************************************************
    section.common-questions {
        .row {
            transition: opacity 1200ms $cubic-bezier-out-expo;
            transition-delay: 1s;
        }
    }

    // SPECIFICATIONS ************************************************************************************
    section.specifications {
        background-color: $dark;
        color: $light;

        .row {
            --bs-gutter-x: 6rem;
        }

        dl {
            transition: opacity 1200ms $cubic-bezier-out-expo;
            transition-delay: 1s;

            &::after {
                display: block;
                content: '';
                clear: both;
            }

            dt {
                float: left;
                width: 45%;
                margin: .6rem 0;
            }

            dd {
                float: left;
                width: 55%;
                margin: .6rem 0;
                padding-left: 1.5rem;
                position: relative;
                text-align: end;
            }

            hr {
                clear: both;
                display: block;
                width: 100%;
                height: 1px;
                border-top: 1px solid $light;
                opacity: 1;
                margin-bottom: 0;
            }

            &.dl-small {
                dt, dd {
                    font-size: 1.3rem;
                }
            }
        }

        .table-responsive {
            transition: opacity 1200ms $cubic-bezier-out-expo;
            transition-delay: 1s;
        }
    }

    // DOWNLOAD ******************************************************************************************
    section.download {

        .title {
            margin-bottom: 4rem;
        }

        .row {
            transition: opacity 1200ms $cubic-bezier-out-expo;
            transition-delay: 1s;
        }

        a {
            font-weight: 700;
            text-decoration: none;
            font-size: 2rem;
            display: flex;
            line-height: 1.15;
            font-size: 1.7rem;

            @include media-breakpoint-up(md){
                font-size: 2rem;
            }

            @include media-breakpoint-up(xxxl){
                font-size: 2.2rem;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    // OTHER PRODUCTS ***********************************************************************************
    section.other-products {
        background-color: $gray-100;

        .title {
            margin-bottom: 4rem;
        }

        .row {
            transition: opacity 1200ms $cubic-bezier-out-expo;
            transition-delay: 1s;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            font-family: $headings-font-family;
            text-transform: lowercase;
            line-height: 1.25;

            li {
                display: block;
                position: relative;
                margin: .5rem 0;

                @include media-breakpoint-up(md){
                    margin: 0;
                }

                a {
                    text-decoration: none;
                    transition: color 100ms ease-out;

                    &:hover,
                    &:focus,
                    &.selected {
                        color: rgba(var(--bs-primary-rgb), 1);
                    }

                    @include media-breakpoint-down(md){
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
        }

        .secondary {
            @include font-size($h5-font-size);

            @include media-breakpoint-up(md){
                @include font-size($h6-font-size);
            }

            @include media-breakpoint-up(xxxl){
                @include font-size($h5-font-size);
            }

            ul {
                @include media-breakpoint-down(md){
                    display: flex;
                }

                li {
                    a {
                        display: block;
                        width: 100%;
                        position: relative;
        
                        &.selected::after {
                            @include media-breakpoint-up(md){
                                content: "";
                                display: block;
                                width: 4rem;
                                height: 1px;
                                position: absolute;
                                bottom: calc(50% - 1px);
                                right: 0;
                                background-color: $dark;
                            }
                        }
                    }
                }
            }
        }

        .tertiary {
            position: relative;

            @include font-size($h6-font-size);
            line-height: 1.3;

            @include media-breakpoint-up(md){
                @include font-size(2rem);
                padding-left: 3rem;
            }

            @include media-breakpoint-up(xxxl){
                @include font-size($h6-font-size);
            }

            &::after {
                @include media-breakpoint-up(md){
                    content: '';
                    display: block;
                    width: 1px;
                    height: 100%;
                    background-color: $dark;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                }
            }

            ul {
                display: flex;
                flex-wrap: wrap;

                @include media-breakpoint-up(md){
                    min-height: 11rem;
                }

                li {
                    width: 50%;

                    @include media-breakpoint-up(md){
                        width: 25%;
                    }

                    a {
                        display: inline-block;
                        padding: 0 .75rem .5rem 0;

                        @include media-breakpoint-up(md){
                            padding: .5rem .75rem;
                        }
                    }
                }
            }
        }
    }
}

.lm-home {    
    .prod-landing {
        // .lower-bar {
        //     background-color: rgba(var(--bs-secondary-rgb), .5);
        //     backdrop-filter: blur(1rem);
        //     -webkit-backdrop-filter: blur(1rem);
        // }

        .modal {
            &.gallery-modal {
                .modal-content {
                    background-color: rgba(var(--bs-light-rgb), 1);

                    .ratio {
                        background-color: rgba(var(--bs-secondary-rgb), 1);
                    }

                    .btn-sm {
                        padding: .5rem 1.5rem;       
                    }
                }
            }
        }
        // section.intro {
        //     background-color: $dark;
        //     color: $light;
        // }
        section.features,
        section.other-products {
            background-color: transparent;

            .title {
                color: $dark;
            }
        }
    }
}

.modal-open {
    .upper-bar,
    .lower-bar {
        z-index: 1000;
    }
}
