/* 1. Include configuration files (functions, variables, mixins, etc...) */
@import "config";

/* 2. Add additional custom code here */
:root {
    font-size: 62.5%; /* (62.5/100) * 16px = 10px */

    --bs-link-color: $dark;
    --bs-link-hover-color: $primary;
}

[data-lastpass-icon-root] {
    display: none !important;
}

a:focus {
    outline-offset: 5px;
    outline-color: $primary;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    text-transform: lowercase;
}

@include media-breakpoint-up(xxxl){
    h1, .h1 {
        @include font-size(1rem * 10);
    }
    h2, .h2 {
        @include font-size(1rem * 8);
    }
    h3, .h3 {
        @include font-size(1rem * 7);
    }
    h4, .h4 {
        @include font-size(1rem * 6);
    }
    h5, .h5 {
        @include font-size(1rem * 5);
    }
    h6, .h6 {
        @include font-size(1rem * 4);
    }
}

.btn {
    --bs-btn-focus-box-shadow: 0 0 0 0.5rem rgba(var(--bs-btn-focus-shadow-rgb), .45);
    text-decoration: none !important;
    text-transform: uppercase;
    transition: all 600ms $cubic-bezier;

    &:hover,
    &:focus {
        transform: scale(1.05);
    }

    &.btn-fake {
        pointer-events: none;
        cursor: default;
    }

    &.btn-light {
        border-color: $dark;
    }

    @include media-breakpoint-down(md){
        padding: 1rem 2.5rem;
        font-size: 1.3rem;
    }
}

.btn-nav-arrow {
    --bs-btn-padding-x: 1.95rem;
    min-width: 5.35rem;

    @include media-breakpoint-down(md){
        min-width: 4.15rem;
        padding: 1rem;
    }

    &.btn-sm {
        --bs-btn-padding-x: 1.39rem;
        min-width: 4.1rem;
    }

    &.swiper-button-disabled {
        &.btn-dark {
            color: rgba($light, .35);
        }
        &.btn-light {
            color: rgba($dark, .35);
        }
    }

    span.reveal {
        display: none;
    }

    &:hover,
    &.always-on {
        span.reveal {
            display: inline;
            margin-left: 1rem;
        }
    }

    &.always-on {
        animation: bounceSideTools 1s infinite;
        animation-iteration-count: 4;
    }
}

.main-container {
    overflow: hidden;
    @include font-size(1.6rem);

    @include media-breakpoint-up(xxxl){
        @include font-size(1.8rem);
    }
}

.pages {
    position: relative;

    a {
        text-decoration: underline;
    
        &:hover {
            text-decoration: none;
        }
    }
}

section {
    position: relative;
}

header {
    width: 100%;
    transition: transform 400ms ease-out;
    position: absolute;
    z-index: 3;
    padding-left: calc($grid-gutter-width / 2);
    padding-right: calc($grid-gutter-width / 2);

    .logo {
        @include media-breakpoint-up(lg){
            padding: 1.9rem 0;
        }

        .logo-desktop {
            display: block;
            width: 9rem;
            height: 6rem;

            @include media-breakpoint-up(lg){
                margin: 0;
            }

            @include media-breakpoint-up(xxxl){
                width: 10.5rem;
                height: 7.2rem;
            }
        }

        div {
            height: 7rem;
            width: 1px;
            background-color: $light;
            margin: auto 1.5rem auto 2rem;
            display: block;
        }
    }
}

footer,
.external-link-modal,
.countries-modal {
    .logo {
        display: block;
        width: 100%;
        height: auto;
        max-width: calc(map-get($container-max-widths, 'xxxl') - $grid-gutter-width);
        margin: 4rem auto 0;
        pointer-events: none;

        @include media-breakpoint-up(md){
            margin: 7rem auto 0;
        }

        @include media-breakpoint-up(xxxl){
            margin: 10rem auto 0;
        }
    }

    .display-6 {
        line-height: 1.35;
    }
}

.external-link-modal {
    @include media-breakpoint-down(md){
        font-size: 1rem;
    }
}

.skip-content {
    position: absolute;
    left: -10000px;
    top: 0;
    padding: 1rem 2rem;
    background-color: $primary;
    color: $light;
    font-size: 1.8rem;
    font-weight: 700;
    z-index: 20;
    text-decoration: none;
    
    &:focus {
        left: 0;
        outline-offset: 0 !important;
    }

    &:hover {
        color: rgba($light, .75);
    }
}

#btnScrollUpPage {
    position: fixed;
    z-index: 1;
    bottom: 3rem;
    right: 3rem;
    transition: transform 400ms ease-out;

    &.away {
        transform: translateY(200%);
    }
}

.energy-saver{
    background-color: #052103;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100000;
    display: none;
    cursor: pointer;

    .energy-saver--logo { 
        width: 9rem;
        height: auto;
        display: block;
        margin: 0 auto .5rem;

        path {
            fill: #236B14;
        }
    }
    
    .energy-saver--content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 34rem;
        height: 30rem;
        display: block;
        margin-left: -17rem;
        margin-top: -15rem;
        color: #236B14;
        font-size: 1.4rem;
        line-height: 1.4;
        text-align: center;

        @include media-breakpoint-down(md){
            width: 24rem;
            margin-left: -12rem;
        }

        p {
            font-weight: 300;
            width: 100%;
            margin: 0;
        }
    }

    .energy-saver--ico{
        padding: 0 .5rem;
        display: inline-block;
        margin: 0 auto;

        svg {
            width: 4rem;
            height: 4rem;
            margin-top: 1.5rem;
        }
    }

    .energy-saver--cred {
        position: absolute;
        bottom: 2rem;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        color: #236B14;
        font-size: 1rem;

        a {
            color: #236B14;
            text-decoration: none;
            transition: color 300ms ease-out;

            &:hover {
                color: #236B14;
            }
        }
    }
}

.scroll-down {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    width: 3rem;
    @include font-size(1.2rem);
    transition: color 600ms ease-out;

    @include media-breakpoint-up(xl){
        @include font-size(1.4rem);
    }

    .scroll-down--txt {
        text-transform: lowercase;
        margin-right: .3rem;
        padding-bottom: 3.5rem;

        @include media-breakpoint-down(md){
            padding-bottom: 2rem;
        }
    }

    .scroll-down--bar {
        height: 100%;
        width: .4rem;
        overflow: hidden;

        @include media-breakpoint-up(xl){
            height: 100%;
        }

        .scroll-down--bar-in {
            background-color: $dark;
            transition: background-color 600ms ease-out;
            width: 100%;
            height: 100%;
            animation: scrollDown 2s infinite;
        }
    }

    &.background--dark {
        .scroll-down--bar {
            .scroll-down--bar-in {
                background-color: $light;
            }
        }
    }
}

@keyframes scrollDown {
    0% {
        transform: translateY(-100%);
    }
    50% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
    }
}

.btn-tertiary {
    text-decoration: none !important;
    text-transform: lowercase;
    font-family: $headings-font-family;
    @include font-size(2.8rem);

    @include media-breakpoint-up(md){
        @include font-size(2.2rem);
    }

    @include media-breakpoint-up(xxxl){
        @include font-size(2.8rem);
    }

    .icon {
        @include font-size(1.1rem);
        transition: transform 200ms $cubic-bezier;
        vertical-align: 0;

        @include media-breakpoint-up(md){
            @include font-size(1.2rem);
        }

        @include media-breakpoint-up(xxxl){
            @include font-size(1.6rem);
        }

        &.fa-chevron-right {
            margin-left: .5rem;
        }

        &.fa-chevron-left {
            margin-right: .5rem;
        }
    }

    &:hover,
    &:focus {

        .icon.fa-chevron-right {
            transform: translateX(.4rem);
        }
        .icon.fa-chevron-left {
            transform: translateX(-.4rem);
        }
    }
}

.upper-bar {
    top: 0;
    left: 0;
    width: 100%;
    @include padding(1.05rem 2rem);
    z-index: 1;
    color: $light;
    transform: translateY(-100%);

    @include media-breakpoint-up(lg){
        @include padding(1.5rem 3rem);
        z-index: 3;
    }

    @include media-breakpoint-up(xxxl){
        @include padding(2.2rem 3rem);
    }

    &.abs {
        position: absolute;
        transform: translateY(0);
    }

    &.fix {
        position: fixed;
        backdrop-filter: blur(1rem);
        -webkit-backdrop-filter: blur(1rem);
        transition: transform 600ms $cubic-bezier, background-color 200ms ease-out;
        transform: translateY(0);
        background-color: rgba($dark, .95);
        color: $light;
        transform: translateY(-100%);

        &.is-visible {
            transform: translateY(0);
        }

        &.away {
            transform: translateY(-100%);
        }
    }

    &.dark {
        background-color: rgba($dark, 1);
        color: $light;

        &.fix {
            background-color: rgba($dark, .85);
        }
    }

    .upper-bar--name {
        max-width: 63%;
    }

    .upper-bar--nav {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            font-size: 1.5rem;
            font-weight: 700;
            text-transform: uppercase;

            li:not(:first-child) {
                margin-left: 3rem;
            }

            a {
                text-decoration: none;
                opacity: .5;

                &.active,
                &:hover {
                    opacity: 1;
                }

                &.active {
                    text-decoration: underline;
                    text-underline-offset: .5rem;
                }
            }
        }
    }
}

.lower-bar {
    bottom: 0;
    left: 0;
    width: 100%;
    @include padding(1.05rem 2rem);
    z-index: 1;
    color: $light;
    transform: translateY(100%);

    @include media-breakpoint-up(lg){
        @include padding(1.5rem 3rem);
        z-index: 3;
    }

    @include media-breakpoint-up(xxxl){
        @include padding(2.2rem 3rem);
    }

    &.abs {
        position: absolute;
        transform: translateY(0);
    }

    &.fix {
        position: fixed;
        backdrop-filter: blur(1rem);
        -webkit-backdrop-filter: blur(1rem);
        transition: transform 600ms $cubic-bezier, background-color 200ms ease-out;
        transform: translateY(0);
        background-color: rgba($light, .5);
        color: $dark;
        transform: translateY(100%);

        &.is-visible {
            transform: translateY(0);
        }

        &.away {
            transform: translateY(100%);
        }
    }

    &.dark {
        background-color: rgba($dark, 1);
        color: $light;

        &.fix {
            background-color: rgba($dark, .85);
        }
    }

    // &:not(.dark){
    //     .btn-light {
    //         @include media-breakpoint-down(md){
    //             background-color: $danger;
    //             border-color: $danger;
    //             color: $light;
    //         }
    //     }
    // }

    .lower-bar--name {
        max-width: 63%;
    }
}

.pages-vert-side-img {
    width: 20%;
    height: 100vh;
    position: fixed;
    top: 0;
    overflow: hidden;

    &.right-side {
        right: 0;
    }

    &.left-side {
        left: 0;
    }

    &.abs {
       bottom: 0;
       top: auto;
       position: absolute; 
    }

    .inner-wrap {
        transition: transform 2600ms $cubic-bezier-out-expo;
        transform: scale(.95);
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
    }

    .curtain {
        transition: all 1800ms $cubic-bezier-inout-quint;
        background-color: rgba(var(--bs-light-rgb), 1);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &.active {
        .inner-wrap {
            transform: scale(1.05);
        }

        .curtain {
            width: 0%;
        }

        &.left-side {
            .curtain {
                transform: translateX(100%);
                width: 100%;
            }
        }
    }
}

section.video-hero {
    width: 100%;
    height: 100vh;
    background-color: #000;

    @include media-breakpoint-down(md){
        height: 100vh;
        height: var(--fix-100vh, 100vh);
        min-height: auto;
    }

    #videoHero {
        position: absolute;
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }

    h1 {
        @include media-breakpoint-down(md){
            font-size: $h5-font-size;
        }
    }

    p {
        @include media-breakpoint-down(md){
            font-size: 2.2rem !important;
        }
    }

    // .prod-img {
    //     width: 35%;
    //     height: auto;

    //     @include media-breakpoint-down(xl){
    //         width: 50%;
    //     }

    //     @include media-breakpoint-down(lg){
    //         width: 65%;
    //     }

    //     @include media-breakpoint-down(md){
    //         width: 85%;
    //     }

    //     @media screen and (min-width: 1600px){
    //         width: 45%;
    //     }
    // }

    // .lower-bar {
    //     @include media-breakpoint-down(md){
    //         display: none !important;
    //     }
    // }
}

section.contact-us {
    position: relative;
    padding: 6rem 0 0;

    @include media-breakpoint-up(md){
        padding: 12rem 0 0;
    }

    .subtitle {
        color: rgba($light, .75);
    }
}

.countries-modal {
    .countries-list {
        --bs-gutter-y: 3rem;
        margin: 0 0 6rem;

        @include media-breakpoint-up(md){
            --bs-gutter-y: 4.5rem;
        }

        @include media-breakpoint-up(lg){
            --bs-gutter-y: 6rem;
        }
    }

    .country {
        display: flex;
        transition: color .3s ease-out;
    
        &:hover,
        &:focus-within {
            color: rgba($light, .5);
        }
    
        .pin {
            font-size: 2rem;
            margin: 0 1.3rem 0 0;
    
            @include media-breakpoint-up(md){
                font-size: 2.4rem;
            }

            @include media-breakpoint-up(xl){
                font-size: 3rem;
            }
        }
    
        .name {
            font-family: $headings-font-family;
            text-transform: lowercase;
            @include font-size(2.4rem);
            line-height: 1.15;
            text-align: left;
    
            @include media-breakpoint-up(xxxl){
                @include font-size(2.8rem);
            }
        }
    
        a {
            text-decoration: none;
    
            .svg-inline--fa {
                margin-left: .5rem;
                transform: translateY(.2rem);
            }
        }
    }
}

.modal {
    .logo {
        position: absolute;
        z-index: 3;
        top: 2rem;
        left: 3rem;

        @include media-breakpoint-only(md){
            top: 3rem;
        }

        @include media-breakpoint-down(md){
            top: 1.5rem;
            left: 2rem;
        }

        @include media-breakpoint-up(xxxl){
            top: 3.4rem;
        }

        .logo-desktop {
            display: block;
            width: 9rem;
            height: 6rem;

            @include media-breakpoint-up(xxxl){
                width: 10.5rem;
                height: 7.2rem;
            }
        }

        div {
            height: 5rem;
            width: 1px;
            background-color: $dark;
            margin: auto 1.5rem auto 2rem;
            display: block;

            @include media-breakpoint-up(md){
                height: 7rem;
            }
        }

        .logo-home {
            width: 12rem;
            height: 7.2rem;
            display: none;

            @include media-breakpoint-up(lg){
                //width: 10rem;
                width: 20rem;
                height: 6.2rem;
            }

            @include media-breakpoint-up(xxxl){
                //width: 12rem;
                width: 24rem;
                height: 7.2rem;
            }
        }

        .logo-mobile {
            width: 7.8rem;
            height: 3.8rem;
            display: block;

            @include media-breakpoint-up(lg){
                display: none;
            }
        }
    }

    .btn-close {
        position: absolute;
        top: 3rem;
        right: 3.5rem;
        z-index: 4;
        padding: 0;
        border: 0;
        background: transparent;
        font-size: 4rem;
        line-height: 1;
        min-width: 4.05rem;

        @include media-breakpoint-up(xxxl){
            top: 5.3rem;
        }

        @include media-breakpoint-down(md){
            top: 1.5rem;
            right: 2rem;
        }

        &.icon-hover {
            @include media-breakpoint-up(xl){
                &::before {
                    width: 130%;
                    height: 130%;
                    top: -15%;
                    left: -15%;
                }
            }
        }
    }

    &.gallery-modal {
        background-color: rgba(var(--bs-light-rgb), 1);

        .swiper {
            padding: 1.5rem 6.5%;

            .ratio {
                background-color: $gray-100;

                // @include media-breakpoint-down(lg){
                //     --bs-aspect-ratio: 125%;
                // }

                .gallery--thumb {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    margin: 0 auto;
                    object-fit: cover;
                }
            }
        }

        .swiper-navigation-btns {
            margin-top: -2rem;
            padding: 0;
            position: relative;

            @include media-breakpoint-up(md){
                margin-top: -2.75rem;
            }
        }

        &.woo-gallery-modal {
            @include media-breakpoint-up(lg){
                background-color: rgba(var(--bs-light-rgb), 1);
                padding-left: 0;
                padding-right: 0;
            }
    
            .logo {
                position: fixed;
            }
    
            .btn-close {
                position: fixed;
            }

            .swiper {
                .ratio {
                    @include media-breakpoint-up(lg){
                        background-color: rgba(var(--bs-light-rgb), 1);
                        pointer-events: none;
                    }
                }
            }

            .swiper-navigation-btns {
                @include media-breakpoint-up(lg){
                    position: absolute;
                    top: 50vh;
                    width: 96%;
                    left: 2%;
                    display: flex;
                    justify-content: space-between;
                }
            }

            .btn-nav-arrow-desktop {
                position: fixed;
                top: 45.5vh;
                z-index: 10;

                @include media-breakpoint-down(lg){
                    display: none !important;
                }

                &.btn-prev {
                    left: 3.5rem;
                }

                &.btn-next {
                    right: 3.5rem;
                }
            }
        }
    }

    &.video-modal {
        .modal-content {
            background-color: #000 !important;
        }

        .btn {
            position: absolute;
            top: 3rem;
            right: 3rem;

            @include media-breakpoint-down(md){
                top: 2rem;
                right: 2rem;
            }
        }

        .btn-close {
            &.icon-hover {
                @include media-breakpoint-up(xl){
                    &::before {
                        background-color: rgba(var(--bs-light-rgb), .1);
                    }
                }
            }
        }

        .logo {
            svg {
                path,
                polygon,
                rect {
                    fill: $light;
                }
            }
        }
    }

    .modal-fullscreen .modal-body {
        overflow: inherit;
    }
}

.modal-fullscreen {
    width: 100%;
}

body.lm-home {
    .modal {
        .logo {
            &.lm-comm {
                display: none;
            }
            &.lm-home {
                display: block;
            }

            .logo-home {
                @include media-breakpoint-up(lg){
                    display: block;
                }
            }
            .logo-home-mobile {
                @include media-breakpoint-down(lg){
                    display: block;
                }
            }
        }
        .modal-content {
            background-color: rgba(var(--bs-light-rgb), 1);
        }
    }

    footer {
        background-color: $dark;
        color: $light;
    }
}

.side-dots {
    position: fixed;
    right: $grid-gutter-width;
    top: 50%;
    text-align: center;
    transform: translateX(300%) translateY(-50%);
    transition: transform 400ms ease-out;

    &.is-visible {
        transform: translateX(0) translateY(-50%);
    }

    .dot {
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid $dark;
        border-radius: 1.5rem;
        display: block;
        margin: 2rem auto;
        transition: all 400ms $cubic-bezier;
        cursor: pointer;

        &.active {
            border-color: rgba(var(--bs-dark-rgb), 1);
            background-color: rgba(var(--bs-dark-rgb), 1);
            opacity: 1;
        }
    }

    &.background--dark {
        color: $light;

        .dot {
            border-color: $light;

            &.active {
                border-color: $light;
                background-color: $light;
            }
        }
    }

    .dots-label {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        text-transform: lowercase;
        font-weight: 700;
        font-size: 1.8rem;
        margin-right: .2rem;
        transform: rotate(180deg);
        transition: color 400ms $cubic-bezier;
        width: auto;
        height: 10rem;
        text-align: left;
        white-space: nowrap;
    }
}

[data-animations]{
    .init-anim {
        opacity: 0;
    }
}

button[type="submit"]{
    min-width: 20rem;
}

.form-select:valid:not([multiple]):not([size]), 
.form-select:invalid:not([multiple]):not([size]) {
    background-position: right 1.5rem center, right 4rem center !important;
}

.prod-thumb {
    background-color: rgba(178, 178, 179, 0.08);
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: calc((451 / 338) * 100%);
    }

    .prod-thumb--main-link {
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
    }

    .prod-thumb--img {
        position: absolute;
        top: 45%;
        transform: translateY(-50%);
        right: 0;
        left: 0;
        width: 80%;
        height: auto;
        margin: 0 auto;
        transition: transform 600ms $cubic-bezier;
    }

    .prod-thumb--bottom {
        position: absolute;
        width: 86%;
        left: 7%;
        bottom: 5%;
        line-height: 1.1;

        .prod-thumb--name {
            font-weight: 700;
            text-transform: lowercase;

            @include media-breakpoint-up(xxxl){
                font-size: 2.2rem;
            }
        }

        .prod-thumb--price {
            @include font-size(1.1rem);
            margin-top: .5rem;

            @include media-breakpoint-up(md){
                @include font-size(1.3rem);
            }

            @include media-breakpoint-up(xxxl){
                font-size: 1.6rem;
            }

            del {
                opacity: .5;
                display: block;
            }
        }
    }

    .prod-thumb--top {
        position: absolute;
        width: 90%;
        left: 5%;
        top: 5%;
        line-height: 1.1;
        text-align: end;

        @include media-breakpoint-up(xl){
            display: none;
        }

        .btn {
            padding: .35rem 1.25rem;
            font-size: 1rem;
            -webkit-font-smoothing: antialiased;

            @include media-breakpoint-up(xxxl){
                padding: .55rem 1.5rem;
                font-size: 1.3rem;
            }

            @include media-breakpoint-down(md){
                padding: .25rem .7rem;
                font-size: .85rem;
                line-height: 1.6;
            }
        }
    }

    &:hover {
        @include media-breakpoint-up(xl){
            .prod-thumb--img {
                transform: translateY(-50%) scale(1.05);
            }

            .prod-thumb--top {
                display: block;
            }
        }
    }

    &.horiz-thumb {
        &:before {
            padding-top: calc((338 / 451) * 100%);
        }

        .prod-thumb--img {
            top: 50%;
            width: auto;
            height: 90%;
        }
    }
}

.news-thumb {
    width: 100%;
    max-width: 38rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(178, 178, 179, 0.08);

    .img-fluid {
        transition: transform 600ms $cubic-bezier;
    }

    &:hover {
        .img-fluid {
            @include media-breakpoint-up(xl){
                transform: scale(1.05);
            }
        }
    }

    .news-thumb--inner {
        @include padding(2rem);
        display: flex;
        flex-direction: column;
        height: 100%;

        .news-thumb--date {
            font-weight: 700;
            color: rgba($dark, .65);
        }

        h3 {
            font-size: 2.5rem;
            line-height: 1.25;
    
            @include media-breakpoint-up(xxxl){
                font-size: 2.8rem;
            }
        }

        p {
            margin-bottom: 0;
        }

        .btn-tertiary {
            margin-top: auto;
        }
    }
}

.accordion-button {
    text-transform: lowercase;
    font-size: 2rem;
    justify-content: space-between;

    @include media-breakpoint-up(xxxl){
        font-size: 3rem;
        --bs-accordion-btn-padding-y: 1.5rem;
    }

    &::after {
        margin-left: 1.5rem;
    }
}


.toast-container {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    @include media-breakpoint-up(lg){
        top: 0;
        transform: translateX(-50%) translateY(0);
    }

    .toast {
        margin-top: 7rem;

        @include media-breakpoint-up(lg){
            margin-top: 2rem;
        }
    }
}