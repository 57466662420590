﻿$utilities: map-merge(
    $utilities, (
        "obj-fit": (
            property: object-fit,
            class: obj-fit,
            values: cover contain fill scale-down,
        ),
        "obj-position": (
            property: object-position,
            class: obj-position,
            values: (
                left-top: left top,
                left-center: left center,
                left-bottom: left bottom,
                center-top: center top,
                center-bottom: center bottom,
                right-top: right top,
                right-center: right center,
                right-bottom: right bottom,
            ),
        ),
        "width": (
            property: width,
            class: w,
            responsive: true,
            rfs: true,
            values: (
                25: 25%,
                35: 35%,
                50: 50%,
                65: 65%,
                75: 75%,
                100: 100%,
                auto: auto
            )
        ),
        "vh": (
            property: height,
            class: vh,
            responsive: true,
            rfs: true,
            values: (
                0: 0vh,
                25: 25vh,
                50: 50vh,
                75: 75vh,
                100: 100vh,
                auto: auto
            )
        ),
        "spacer": (
            property: height,
            class: spacer,
            responsive: true,
            rfs: true,
            values: (
                0: 0,
                5: .5rem,
                10: 1rem,
                20: 2rem,
                30: 3rem,
                40: 4rem,
                50: 5rem,
                60: 6rem,
                70: 7rem,
                80: 8rem,
                90: 9rem,
                100: 10rem
            )
        ),
        "svg": (
            property: width,
            class: svg-w,
            values: (
                1: .0625em,
                2: .125em,
                3: .1875em,
                4: .25em,
                5: .3125em,
                6: .375em,
                7: .4375em,
                8: .5em,
                9: .5625em,
                10: .625em,
                11: .6875em,
                12: .75em,
                13: .8125em,
                14: .875em,
                15: .9375em,
                16: 1em,
                17: 1.0625em,
                18: 1.125em,
                19: 1.1875em,
                20: 1.25em
            )
        ),
        "bg-size": (
            property: background-size,
            class: bg-size,
            responsive: true,
            rfs: true,
            values: (
                0: 0,
                5: 5%,
                10: 10%,
                15: 15%,
                20: 20%,
                25: 25%,
                30: 30%,
                35: 35%,
                40: 40%,
                45: 45%,
                50: 50%,
                55: 55%,
                60: 60%,
                65: 65%,
                70: 70%,
                75: 75%,
                80: 80%,
                85: 85%,
                90: 90%,
                95: 95%,
                100: 10%
            )
        )
    ),
);

[class^="obj-fit-"], [class*=" obj-fit-"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
